import { defineStore } from 'pinia'
import { ref } from 'vue'

import ko from './ko.json'
import vi from './vi.json'
import en from './en.json'

export const useL10nStore = defineStore('l10n', () => {
  let data = ref({});

  function setLang(lang) {
    if (lang === 'ko')
      data.value = ko;
    else if (lang === 'vi')
      data.value = vi;
    else
      data.value = en;
  }

  return { data, setLang }
})
