<template>
  <header ref="container" class="common" :class="index">
    <h1><RouterLink to="/" target="_self">oyoung bt</RouterLink></h1>
    <nav class="gnb">
      <ul>
        <li class="about">
          <RouterLink to="/about" target="_self">{{ L('About Us') }}</RouterLink>
        </li>
        <li class="ai">
          <RouterLink to="/health" target="_self">{{ L('AI Healthcare') }}</RouterLink>
        </li>
        <li class="b2b">
          <RouterLink to="/cosmetic" target="_self">{{ L('Cosmetic B2B Platform') }}</RouterLink>
        </li>
        <li class="contact">
          <RouterLink to="/contact" target="_self">{{ L('CONTACT US') }}</RouterLink>
        </li>
      </ul>
    </nav>
    <button ref="btnMenu" type="button" class="menu" @click.stop="toggleMenu">MENU</button>
    <nav class="menu" @click.stop>
      <ul @click="propagate">
        <li class="about">
          <RouterLink to="/about" target="_self">{{ L('About Us') }}</RouterLink>
          <ul>
            <li><RouterLink to="/about" target="_self">{{ L('Our Story') }}</RouterLink></li>
            <li><RouterLink to="/about#value" target="_self">{{ L('Value') }}</RouterLink></li>
            <li><RouterLink to="/about#mission" target="_self">{{ L('Mission &amp; Vision') }}</RouterLink></li>
            <li><RouterLink to="/about#exitplan" target="_self">{{ L('Exit Plan') }}</RouterLink></li>
          </ul>
        </li>
        <li class="ai">
          <RouterLink to="/health" target="_self">{{ L('AI Healthcare') }}</RouterLink>
          <ul>
            <li><RouterLink to="/health" target="_self">{{ L('AI Healthcare') }}</RouterLink></li>
            <li><RouterLink to="/health#aps" target="_self">{{ L('AI Prediction Solution') }}</RouterLink></li>
            <li><RouterLink to="/health#ags" target="_self">{{ L('AI Guide Solution') }}</RouterLink></li>
          </ul>
        </li>
        <li class="b2b">
          <RouterLink to="/cosmetic" target="_self">{{ L('Cosmetic B2B Platform') }}</RouterLink>
          <ul>
            <li><RouterLink to="/cosmetic" target="_self">{{ L('Cosmetic B2B Platform') }}</RouterLink></li>
            <li><RouterLink to="/cosmetic#private" target="_self">{{ L('OYOUNG Private Label') }}</RouterLink></li>
            <li><RouterLink to="/cosmetic#partner" target="_self">{{ L('Partner Brands') }}</RouterLink></li>
            <li><RouterLink to="/cosmetic#oem" target="_self">{{ L('OEM Consulting') }}</RouterLink></li>
          </ul>
        </li>
        <li class="contact">
          <RouterLink to="/contact" target="_self">{{ L('Contact Us') }}</RouterLink>
        </li>
      </ul>
    </nav>
    <button type="button" class="language" @click.stop="toggleLanguage">LANGUAGE</button>
    <nav class="language" @click.stop>
      <ul>
        <li v-for="(value, key) in LANGUAGES" :key="key" :class="preference.lang === key ? 'on' : ''">
          <a href="#none" target="_self" :title="value" @click.prevent="setLang(key)">{{ key.toUpperCase() }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router';
import { usePreferenceStore } from '../stores/preference';

const LANGUAGES = { ko: '한국어', en: 'English', vi: 'Tiếng Việt' };

export default {
  components: {
    RouterLink,
  },
  setup() {
    const preference = usePreferenceStore()
    return { preference };
  },
  props: {
    index: String,
  },
  computed: {
    path() {
      return this.$route.path;
    },
    LANGUAGES() {
      return LANGUAGES;
    },
  },
  watch: {
    path() {
      this.$nextTick(() => {
        this.$refs.container.querySelectorAll('.gnb li').forEach(el => el.classList.remove('on'));
        this.$refs.container.querySelectorAll('.router-link-active').forEach(el => {
          el.parentElement.classList.add('on');
        });
      });
    },
  },
  mounted() {
    this.setLang(this.preference.lang);
  },
  methods: {
    toggleMenu(e) {
      this.toggleModalButton(e.currentTarget);
      document.querySelector('header.common button.language').classList.remove('on');
    },
    toggleLanguage(e) {
      this.toggleModalButton(e.currentTarget);
      document.querySelector('header.common button.menu').classList.remove('on');
    },
    setLang(lang) {
      this.preference.setLang(lang);
      document.body.classList.remove('fixed');
      document.documentElement.setAttribute('lang', lang);
      document.querySelector('header.common button.language').classList.remove('on');
    },
    propagate() {
      document.body.click()
    },
  },
}
</script>
