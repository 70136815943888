<template>
  <footer class="common">
    <div class="bodyWrap">
      <header>
        <h2>oyoung bt</h2>
        <a :href="privacyFile" target="_self" download>{{ L('Privacy Policy') }}</a>
      </header>
      <article>
        <h3>{{ L('OYOUNG BT CO.,LTD.') }}</h3>
        <ul>
          <li>{{ L('CEO Title') }}</li>
        </ul>
        <ul>
          <li>{{ L('Business license') }} 706-87-01035</li>
          <li>{{ L('Online sales registration number') }} 2019-강원춘천-0489</li>
        </ul>
      </article>
      <article>
        <h3>{{ L('Headquarters') }}</h3>
        <ul>
          <li>{{ L('ADDRESS1') }}</li>
        </ul>
        <h3>{{ L('Seoul Office') }}</h3>
        <ul>
          <li>{{ L('ADDRESS2') }}</li>
          <li>(+82) 70 8808 0888</li>
        </ul>
      </article>
      <article class="copyrights">Copyright ⓒ OYOUNG BT. All Rights Reserved.</article>
    </div>
  </footer>
</template>

<script>
import { usePreferenceStore } from '../stores/preference';

const LANGUAGES = {
  ko: 'Korean',
  en: 'English',
  vi: 'Vietnamese',
}

export default {
  setup() {
    const preference = usePreferenceStore();
    return { preference };
  },
  computed: {
    privacyFile() {
      return `/files/Privacy Statement in ${LANGUAGES[this.preference.lang]}.pdf`;
    },
  }
}
</script>
