import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', component: () => import('../views/HomeView.vue'), alias: ['/en', '/ko', '/vi'] },
    { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
    { path: '/health', name: 'health', component: () => import('../views/HealthcareView.vue') },
    { path: '/cosmetic', name: 'cosmetic', component: () => import('../views/CosmeticView.vue') },
    { path: '/brands', name: 'brands', component: () => import('../views/PartnerBrandsView.vue') },
    { path: '/contact', name: 'contact', component: () => import('../views/ContactView.vue') },
    { path: '/product', name: 'product', component: () => import('../views/ProductView.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { el: `a[name=${to.hash.slice(1)}]`, top: 250 };
      }

      setTimeout(() => window.scrollTo(0, 0), 300)
      return { top: 0 }
    }
  }
})

export default router
