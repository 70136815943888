import './assets/main.scss'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { L } from './utils.js'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.config.globalProperties.L = L;

app.config.globalProperties.lnbSlide = (event, id) => {
  const target = event.target

  target.parentElement.querySelectorAll('button').forEach(el => el.classList.remove('on'));
  target.classList.add('on');

  const index = Array.prototype.indexOf.call(target.parentNode.children, target);
  if (!index) {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    return false
  }

  let targetSection;
  if (!id) {
    targetSection = document.querySelector(`main>section:nth-child(${index + 1})`);
  } else {
    targetSection = document.getElementById(id);
  }

  const menuHeight = document.querySelector('header.common').offsetHeight;
  const targetSectionTop = targetSection.getBoundingClientRect().top
    + (document.documentElement.scrollTop < menuHeight ? -menuHeight : document.documentElement.scrollTop)
    - menuHeight * 2 + 30;
  document.documentElement.scrollTo({ top: targetSectionTop, behavior: 'smooth' });
}

app.config.globalProperties.toggleModalButton = (btn) => {
  btn.classList.toggle('on');
  if (btn.classList.contains('on')) {
    document.body.classList.add('fixed');
  } else {
    document.body.classList.remove('fixed');
  }
}

app.config.globalProperties.closePopup = (e) => {
  e.currentTarget.closest('.popupWrap').previousElementSibling.classList.remove('on');
  document.body.classList.remove('fixed');
}

app.mount('#app')
