<script setup>
import { RouterView } from 'vue-router';

import PageFooter from './components/PageFooter.vue';
import PageHeader from './components/PageHeader.vue';
</script>

<template>
  <PageHeader :index="$route.path === '/' ? 'index' : ''" />

  <RouterView />

  <PageFooter />
</template>

<script>
export default {
  mounted() {
    document.body.addEventListener('click', (e) => {
      document.querySelectorAll('header.common button.menu.on,header.common button.language.on, button.morePopup.on')
        .forEach((el) => el.classList.remove('on'));
      document.body.classList.remove('fixed');
    });
  },
};
</script>
