import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useL10nStore } from './l10n.js'

function checkLangValue(langValue) {
  langValue = langValue?.slice(0, 2);

  if (langValue !== 'ko' && langValue !== 'vi')
    return 'en';

  return langValue;
}

export const usePreferenceStore = defineStore('preference', () => {
  const pathLang = location.pathname.length === 3 ? location.pathname.slice(1, 3) : null;
  let initialLang = checkLangValue(pathLang || localStorage.getItem('lang') || navigator.language || 'ko');

  const lang = ref(initialLang)

  function setLang(langValue) {
    lang.value = checkLangValue(langValue);
    localStorage.setItem('lang', langValue);

    useL10nStore().setLang(langValue);
  }

  return { lang, setLang }
})
